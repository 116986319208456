import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import Breadcrumbs from '../../../../../components/Breadcrumbs';
import YoutubeIcon from '../../../../../components/icons/YoutubeIcon';

import Link from '../../../../../components/Link';
import Seo from '../../../../../components/Seo';

const HolosinniaPage = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      songs: allSong(filter: {category: {eq: "holosinnia"}}, sort: {fields: title}) {
        nodes {
          title
          slug
          hasYoutube
        }
      }
    }
  `);

  const songs = data.songs.nodes;

  return (
    <>
      <Seo
        title="Голосіння"
        description="Голосіння – жанр народної творчостi, художнє вираження туги й горя, болю й розпуки. До жанру голосінь відносять народно-поетичні твори, пов’язані з похоронним обрядом."
      />
      <div className="bg-red-500 text-white">
        <div className="container-lg py-10 md:py-24">
          <Breadcrumbs
            crumbs={pageContext.breadcrumb.crumbs}
          />
          <h1 className="typo-h1 lowercase mt-4">
            Голосіння
          </h1>
          <p className="typo-body mt-4 max-w-lg">
            Голосіння – жанр народної творчостi, художнє вираження туги й горя, болю й розпуки.
          </p>
        </div>
      </div>
      <div className="container-lg mt-10 md:mt-24">
        <h2 className="typo-h2">
          Список голосінь
        </h2>
        <ul className="row mt-8">
          {songs.map((song) => (
            <li className="col-full md:col-4 mb-4">
              <Link to={song.slug} className="typo-small flex items-center hover:text-red-500">
                {song.title}
                {song.hasYoutube && (
                  <YoutubeIcon className="ml-2 w-4 h-4" />
                )}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="container-lg py-10 md:py-24">
        <h2 className="typo-h2">
          Опис жанру голосіння
        </h2>
        <p className="typo-body mt-4">
          Голосіння — це народно-поетичні твори, пов’язані з похоронним обрядом. В їх основі лежать стародавні уявлення
          про те, що померлий продовжує існувати в невідомому далекому краю, але його зв’язок з оточенням, в якому
          перебував до смерті, триває. Тому в голосіннях зверталися до мерця, як до живого, виповідаючи йому свої жалі,
          вихваляючи, прагнучи “розбудити” і благаючи повернутися. Виконували цю частину обряду жінки.
        </p>
        <p className="typo-body mt-4">
          Голосіння мають усталену композицію, образність, ритміку, а подеколи й римування, що зближує їх з іншими
          видами обрядової пісенності. Саме ці ознаки дозволили В. Гнатюкові твердити, що хоч “похоронні голосіння…
          переважно відбігли далеко від звичайної пісні, але… належать цілковито до неї… Голосіння в’яжуться стисло
          з похоронним обрядом”. [1]
        </p>
        <p className="typo-body mt-4">
          Різновидом голосінь є рекрутські й солдатські плачі під час проводів на царську службу, яка тривала 25 років,
          отже батьки, наречена чи жінка розлучалися з новобранцем, не маючи надії на його повернення: «Сину мій, дитино
          моя! Я ж тебе кохала, ніченьку не спала, тебе доглядала… Мій рідненький, моя й жалоба! Із якої дороги тебе
          ожидать, відкіль виглядать? чи з поля, чи з моря, чи з далекого краю?» Українські голосіння близькі за
          емоційною тональністю, тематикою й поетикою до аналогічних жанрів поезії інших слов'янських народів, особливо
          східнослов'янських — російського й білоруського. Генетично вони пов'язані з думами, їх єднає речитативний
          спосіб виконання, тужливі ноти. Певна спорідненість простежується у голосіннях, піснях-хроніках і баладах,—
          насамперед тоді, коли йдеться про оплакування близьких, які загинули внаслідок трагічних випадків, або тих, що
          пропали безвісти на війні чи на заробітках. Так, пісні-хроніки можуть обмежуватися лише повідомленням про
          голосіння: «Та зачєла Марієчка сама голосити» — або наводити його повний текст:
        </p>
        <p className="typo-body italic mt-4">
          Сяду ввечер разом з дітьми і разом заплачем:
          <br />
          Ой коли ж ми, любі діти, татуню забачим?
        </p>
        <p className="typo-body italic mt-4">
          Лишив-єс мя, молоденьку, і дрібненькі діти,
          <br />
          Як я тепер буду з ними, бідна, жити в світі?
          <br />
          Лишила-м ся з діточками, як билина в полі,
          <br />
          Діти встанут, їсти плачут, нещаслива доле!
          <br />
          Діти встанут, їсти плачут, а нема що дати,
          <br />
          Ходят голі, обідрані, а нема в що вбрати.
          <br />
          Повій, вітре, буйнесенький, та по нашім полю,
          <br />
          Прийди, мужу, подивися на нашу недолю...
        </p>
        <p className="typo-body mt-4">
          В піснях-хроніках про загиблих жовнірів натрапляємо й на прадавні мотиви голосінь, в яких ведеться діалог між
          птахами і душами загиблих:
        </p>
        <p className="typo-body italic mt-4">
          ...Надлетіла зозуленька та й заголосила:
          <br />
          Уставайте, жовнірику, бо вже біла днина!
          <br />
          Ой та так би, зозуленько, наш пан цісар устав,
          <br />
          Як на моїй головочці ворон не закранкав.
          <br />
          Ой та он-бо не кранкає, а очі довбає,
          <br />
          Не одного жовнярика мати вже плакає.
        </p>
        <p className="typo-body mt-4">
          В баладах з мотивом смерть-одруження також вплетені голосіння наприклад:
        </p>
        <p className="typo-body italic mt-4">
          ...Ой приїхав козаченько на нове подвір’я,
          <br />
          А там грають, пригравають на смутне весілля.
          <br />
          А як зайшов козаченько до нової хати,
          <br />
          Та почав він над дівчинов рученьки ламати.
          <br />
          — Губки ж мої тоненькії, чо-сьте так стулились?
          <br />
          Як я їхав з цего села, ви ще говорили!
          <br />
          Ручки ж мої біленькії, чо вас так ісклали?
          <br />
          Як я Їхав з цего села, ви ще вишивали.
          <br />
          Ніжки ж мої біленькії, чо вас так зложили?
          <br />
          Як я їхав з цего села, ви ще виходили.
          <br />
          Очка ж мої чорненькії, чо вас так стулили?
          <br />
          Як я їхав з цего села, ви ще ся дивили.
          <br />
          Очка ж мої чорненькії, брови, як шнурочок,
          <br />
          Губки ж мої ніжненькії лиш до співаночок!
        </p>
        <p className="typo-body mt-4">
          Певні аналогії можна провести між голосіннями та ліричними піснями про жіночу недолю.
        </p>
        <p className="typo-body mt-4">
          В голосіннях відображено поетичну специфіку, побут і громадсько-соціальні погляди селянства XVIII-XIX ст.,
          коли цей жанр був досить активним і широко фіксувався фольклористами.
        </p>
        <p className="typo-body mt-4">
          Традиційні обряди й голосіння зображені у творчості українських класиків Т. Шевченка («Тризна»),
          Г. Квітки-Основ'яненка («Маруся»), Марка Вовчка («Горпина»), М. Коцюбинського («Тіні забутих предків»).
        </p>
        <p className="typo-body mt-4">
          Збирання й публікація українських голосінь почалися з середини XIX ст. У книзі О. Терещенка
          «Быт русского народа» 1848 р. вміщено українські голосіння в перекладі та оригіналах. Найбільш повними
          виданнями цього жанру є збірник В. Милорадовича «Народные обряды и песни Лубенского уезда Полтавской губернии»
          (Харків, 1897), видання «Похоронні голосіння» І. Свєнціцького та «Похоронні звичаї й обряди» В. Гнатюка
          (Етнографічний збірник, т. XXXI-XXXII, 1912). Зразки голосінь подано у збірнику «Народна лірика» (К., 1941).
          Ф. Колесса видав дослідження «Українські народні думи у відношенню до пісень, віршів і похоронних голосінь»
          (ЗНТШ, томи СХХХ—СХХХІІ, 1920–1922). Розділ «Голосіння» в книзі «Українська народна поетична творчість»
          (К., 1959) опублікував М. Стельмах. Він підкреслив, що похоронні голосіння, в яких відобразилися залишки
          старих вірувань, уявлень, а також рекрутські плачі, ставши реліктами минулого, зберігають свою цінність як
          поетичні зразки народної творчості, що мають художню й пізнавальну силу.
        </p>
        <h3 className="typo-h3 mt-14">
          Примітки
        </h3>
        <p className="typo-body mt-4">
          [1] Гнатюк В. Похоронні голосіння. Похоронні звичаї й обряди. Від редакції // Етнографічний збірник. — Т.
          ХХХІ — ХХХІІ. — Львів, 1912.
        </p>
      </div>
    </>
  );
};

export default HolosinniaPage;
